import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [

  {
    label: t('Home'),
    icon: 'HomeUpdatedIcon', /* Home */
    href: 'https://muftswap.exchange/',
  },
  {
    label: t('Exchange'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Swap'),
        href: 'https://trade.muftswap.exchange/#/swap', 
      },
      {
        label: t('Liquidity'),
        href: 'https://trade.muftswap.exchange/#/liquidity',
      }
    ],
  },
  {
    label: t("Devil`s Farms"),
    icon: 'FarmUpdatedIcon',
    href: 'https://muftswap.exchange/farms',
  },
  {
    label: t('Red Pools'),
    icon: 'PoolIcon',
    href: 'https://muftswap.exchange/pools',
  },
  {
    label: 'Doom Pit',
    icon: 'DoomPitIcon',
    href: 'https://muftswap.exchange/doompit',
  },
  {
    label: 'Football Predictions',
    icon: 'PredictionIcon',
    href: 'https://muftswap.exchange/prediction',
  },
  {
    label: 'Football Games',
    icon: 'GameIcon',
    href: 'https://muftswap.exchange/game',
  },
  {
    label: t('SNFT Marketplace'),
    icon: 'HomeIcon',
    href: 'https://muftswap.exchange/snft',
  },
  {
    label: t('Audits'),
    icon: 'LotteryIcon',
    href: 'https://github.com/solidproof/smart-contract-audits/blob/main/SmartContract_Audit_Solidproof_ManUtdFantoken.pdf',
  },
  {
    label: t('ILO'),
    icon: 'MoonIcon',
    href: '/',
  },
  {
    label: t('Listings'),
    icon: 'MoreIcon',
    items: [
      {
        label: t('CoinMarketCap'),
        href: 'https://coinmarketcap.com/currencies/muftswap/',
      },
      {
        label: t('CoinMooner'),
        href: 'https://coinmooner.com/coin/6924/',
      },
      {
        label: t('CoinGecko'),
        href: '/#',
      },
      {
        label: t('FTX'),
        href: '/#',
      },
    ],
  },
  // {
  //   label: t('More'),
  //   icon: 'More',
  //   items: [
  //     {
  //       label: t('Docs'),
  //       href: '/docs',
  //     },
  //     // {
  //     //   label: t('Whitepaper'),
  //     //   href: '/whitepaper',
  //     // },
  //     // {
  //     //   label: t('Github'),
  //     //   href: '/github',
  //     // },
  //     // {
  //     //   label: t('Price Charts'),
  //     //   href: '/pricecharts',
  //     // },
  //   ],
  // },
]

export default config
