export const GRAPH_API_PROFILE = process.env.REACT_APP_GRAPH_API_PROFILE
export const GRAPH_API_PREDICTION = process.env.REACT_APP_GRAPH_API_PREDICTION
export const GRAPH_API_LOTTERY = process.env.REACT_APP_GRAPH_API_LOTTERY
export const SNAPSHOT_VOTING_API = process.env.REACT_APP_SNAPSHOT_VOTING_API
export const SNAPSHOT_BASE_URL = process.env.REACT_APP_SNAPSHOT_BASE_URL
export const API_PROFILE = process.env.REACT_APP_API_PROFILE
export const SNAPSHOT_API = `${SNAPSHOT_BASE_URL}/graphql`
export const SNAPSHOT_HUB_API = `${SNAPSHOT_BASE_URL}/api/message`
export const BITQUERY_API = 'https://graphql.bitquery.io'

/**
 * V1 will be deprecated but is still used to claim old rounds
 */
export const GRAPH_API_PREDICTION_V1 = 'https://api.thegraph.com/subgraphs/name/pancakeswap/prediction'

export const INFO_CLIENT = 'https://bsc.streamingfast.io/subgraphs/name/pancakeswap/exchange-v2'
export const BLOCKS_CLIENT = 'https://api.thegraph.com/subgraphs/name/pancakeswap/blocks'

/* 
 * coinpaprika api list
 */
export const fetchMswapInfo = 'https://api.coinpaprika.com/v1/coins/mswap-muftswap-token/markets'
export const fetchMuftInfo = 'https://api.coinpaprika.com/v1/coins/muft-manutd-fan-token/markets'

/* 
 * external urls
 */
export const docsUrl = 'https://mswapdocs.gitbook.io/muft-and-muftswap/'
export const cmcUrl = 'https://coinmarketcap.com/currencies/muftswap/'
export const cmUrl = 'https://coinmooner.com/coin/6924/'
export const auditUrl = 'https://github.com/solidproof/smart-contract-audits/blob/main/SmartContract_Audit_Solidproof_ManUtdFantoken.pdf'
export const iloUrl = 'https://ilo.muftswap.exchange/'
export const swapUrl = 'https://trade.muftswap.exchange/#/swap'
export const liquidityUrl = 'https://trade.muftswap.exchange/#liquidity'
export const ftxUrl = 'https://blockfolio.com/coin/MUFT?timeFrame=1W'
export const buyMuftUrl = 'https://trade.muftswap.exchange/swap?outputCurrency=0x1D4a26a9D980AFC2287Dca15eb896A26384bAc7c'