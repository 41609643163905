import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Spinner } from '@pancakeswap/uikit'
import Page from '../Layout/Page'
import loader from './loader.png'

const Wrapper = styled.div`
  height: 100px;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  position: absolute;
  top: 30%;
  left: 49%;
`
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const RotatingPancakeIcon = styled.img`
  width: 60px;
  height: 60px;

  animation: ${rotate} 2s linear infinite;
  transform: translate3d(0, 0, 0);
`;
const LoadingText = styled.h1`
  margin: 5px;
  font-size: 18px;
`

const PageLoader: React.FC = () => {
  return (
    <Wrapper>
      {/* <Spinner /> */}
      <RotatingPancakeIcon src={loader} alt="loader" />
      <LoadingText>Loading...</LoadingText>
    </Wrapper>
  )
}

export default PageLoader
