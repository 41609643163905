import React from 'react'
import { Flex, IconButton } from '@pancakeswap/uikit'
import styled from 'styled-components'
// import SettingsModal from './SettingsModal'

import twitterImg from './icons/twitterImg.png'
import telegramImg from './icons/telegramImg.png'
import mediumImg from './icons/mediumImg.png'
import redditImg from './icons/redditImg.png'
import facebookImg from './icons/facebookImg.png'

const SocialIcon = styled.img`
  width: 20px;
  height: 20px;
`
const MenuBar = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  @media screen and (max-width: 700px) {
    display: none;
  }
`

const GlobalSettings = () => {
  // const [onPresentSettingsModal] = useModal(<SettingsModal />)
  const onOpenLink = (e: number) => {
    if (e === 1) window.open('https://twitter.com/ManUtdFanToken', '_blank');
    if (e === 2) window.open('https://t.me/muftfantoken', '_blank');
    if (e === 3) window.open('https://www.facebook.com/MUFT-110822411370727', '_blank');
    if (e === 4) window.open('https://www.reddit.com/user/ManUtdFanToken', '_blank');
    if (e === 5) window.open('https://medium.com/@MUFT', '_blank');
  }

  return (
    <MenuBar>
      {/* <IconButton onClick={onPresentSettingsModal} variant="text" scale="sm" mr="8px" id="open-settings-dialog-button">
        <CogIcon height={22} width={22} color="textSubtle" />
      </IconButton> */}
      <IconButton onClick={() => onOpenLink(1)} variant="text" scale="sm" mr="8px" id="open-settings-dialog-button">
        <SocialIcon src={twitterImg} />
      </IconButton>
      <IconButton onClick={() => onOpenLink(2)} variant="text" scale="sm" mr="8px" id="open-settings-dialog-button">
        <SocialIcon src={telegramImg} />
      </IconButton>
      <IconButton onClick={() => onOpenLink(3)} variant="text" scale="sm" mr="8px" id="open-settings-dialog-button">
        <SocialIcon src={facebookImg} />
      </IconButton>
      <IconButton onClick={() => onOpenLink(4)} variant="text" scale="sm" mr="8px" id="open-settings-dialog-button">
        <SocialIcon src={redditImg} />
      </IconButton>
      <IconButton onClick={() => onOpenLink(5)} variant="text" scale="sm" mr="8px" id="open-settings-dialog-button">
        <SocialIcon src={mediumImg} />
      </IconButton>
    </MenuBar>
  )
}

export default GlobalSettings
