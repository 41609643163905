import React, { useEffect, useState } from 'react'
import { Menu as UikitMenu } from '@pancakeswap/uikit'
import { languageList } from 'config/localization/languages'
import { fetchMswapInfo, fetchMuftInfo } from 'config/constants/endpoints'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import { formatAmount } from 'views/Info/utils/formatInfoNumbers'
// import { usePriceCakeBusd } from 'state/farms/hooks'
import { useProfile } from 'state/profile/hooks'
import { useTokenData } from 'state/info/hooks'
import config from './config'
import UserMenu from './UserMenu'
import GlobalSettings from './GlobalSettings'
import { muftContractAddress, bnbContractAddress } from '../../views/Ifos/constants/contratAddresses'

const Menu = (props) => {
  const bnbMswapRate = 0.00006
  // const muftMswapRate = 13440
  const { isDark, toggleTheme } = useTheme()
  const [mswapPriceUsd, setMswapPriceUsd] = useState(0)
  const [muftPriceUsd, setMuftPriceUsd] = useState(0)
  // const cakePriceUsd = usePriceCakeBusd()


  const { profile } = useProfile()
  const { currentLanguage, setLanguage, t } = useTranslation()

  const getMswapLivePrice = async () => {
    try {
      const response = await fetch(fetchMswapInfo)
      const data = await response.json()
      return data
    } catch (err) {
      console.log("bnbLivePriceError", err)
      throw err
    }
  }

  const getMuftLivePrice = async () => {
    try {
      const response = await fetch(fetchMuftInfo)
      const data = await response.json()
      return data
    } catch (err) {
      console.log("bnbLivePriceError", err)
      throw err
    }
  }

  useEffect(() => {
    getMswapLivePrice()
      .then((result) => {
        const mswapLivePrice = result[0].quotes.USD.price
        setMswapPriceUsd(mswapLivePrice)
      })
      .catch((err) => {
        console.log('call api err', err)
      })

    getMuftLivePrice()
      .then((result) => {
        const muftLivePrice = result[0].quotes.USD.price
        setMuftPriceUsd(muftLivePrice)
      })
      .catch((err) => {
        console.log('call api err', err)
      })
  }, [])

  return (
    <UikitMenu
      userMenu={<UserMenu />}
      globalMenu={<GlobalSettings />}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={currentLanguage.code}
      langs={languageList}
      setLang={setLanguage}
      // cakePriceUsd={cakePriceUsd.toNumber()}
      cakePriceUsd={mswapPriceUsd}  // MSWAP USD price
      muftPriceUsd={muftPriceUsd}  // MUFT USD price
      links={config(t)}
      profile={{
        username: profile?.username,
        image: profile?.nft ? `/images/nfts/${profile.nft?.images.sm}` : undefined,
        profileLink: '/profile',
        noProfileLink: '/profile',
        showPip: !profile?.username,
      }}
      {...props}
    />
  )
}

export default Menu
